<template>
  <div>
    <div class="columns is-gapless">
      <div class="column">
        <div class="field has-addons">
          <div class="control has-icons-left has-icons-right">
            <input class="input"
              v-model="filter.query"
              type="text"
              placeholder="Start typing to filter"
              @input="search">
            <span class="icon is-left">
              <i class="mdi mdi-magnify mdi-18px" />
            </span>
          </div>
          <div class="control">
            <a class="button is-outlined tooltip"
              :class="[ filter.butterflyCode ? 'is-warning' : 'is-primary' ]"
              :data-tooltip="filter.butterflyCode ? 'Active Butterfly search' : 'Butterfly search'"
              @click="butterflySearch()">
              <span class="icon">
                <i class="fqi fqi-butterfly fqi-20px" />
              </span>
            </a>
          </div>
          <div class="control">
            <a class="button is-primary is-outlined tooltip"
              data-tooltip="Reset search"
              @click="reset()">
              <span class="icon">
                <i class="mdi mdi-close mdi-24px" />
              </span>
            </a>
          </div>
        </div>
      </div>
      <div class="column is-narrow"
        v-if="showScrollOnAdd"
        style="display: flex; align-items: center">
        <div class="field-body">
          <div class="field is-narrow">
            <input class="is-checkradio"
              id="labourScroll"
              type="checkbox"
              :checked="$userInfo.scrollItemLabour"
              @change="$setScrollItemLabour"
              name="labourScroll">
            <label for="labourScroll">Scroll on add</label>
          </div>
        </div>
      </div>
      <div class="column is-narrow">
        <div class="field is-horizontal is-pulled-right">
          <div class="field-body">
            <div v-if="selectedTimeType === labourTimeTypes.RACQ.replace('-', '').toLowerCase()"
              class="field is-narrow">
              <div class="control">
                <div class="select">
                  <select v-model="selectedRacqBodySize"
                    @change="getAvailableLabours()">
                    <option value="A">A Size</option>
                    <option value="B">B Size</option>
                    <option value="W">Wagon</option>
                    <option value="4">4x4</option>
                    <option value="V">Van</option>
                  </select>
                </div>
              </div>
            </div>
            <div v-if="selectedTimeType === labourTimeTypes.WESF.replace('-', '').toLowerCase()"
              class="field is-narrow">
              <div class="control">
                <div class="select">
                  <select v-model="selectedWesfBodySize"
                    @change="getAvailableLabours()">
                    <option value="Medium">Medium</option>
                    <option value="Large">Large</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="field is-narrow">
              <div class="control">
                <div class="select">
                  <select v-model="selectedTimeType"
                    @change="getAvailableLabours()">
                    <option v-for="(labourTime, propertyName, index) in labourTimeTypes"
                      :key="index"
                      :value="labourTime.replace('-', '').toLowerCase()">
                      {{ labourTime }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div v-if="selectedTimeType === labourTimeTypes.eMTA.replace('-', '').toLowerCase()"
              class="field is-narrow">
              <div class="control">
                <a class="button tooltip is-tooltip-topright"
                  @click="emtaTest"
                  data-tooltip="Change e-MTA vehicle">
                  <span class="icon">
                    <i class="mdi mdi-car" />
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="table-container-avaialble-butterfly-items">
      <bulma-table class="table is-striped is-narrow is-fullwidth"
        :columns="columns"
        :page-index="filter.pageIndex"
        :page-size="filter.pageSize"
        :total-rows="totalRows"
        :is-loading="isTableLoading"
        :sort-column="filter.sortColumn"
        :sort-order="filter.sortOrder"
        @pageChanged="onPageChange"
        @sort="sort">
        <tr v-for="(item, index) in availableItems"
          :key="index">
          <td>{{ item.itemNo }}</td>
          <td @dblclick="addNewItems(item, index)">{{ item.description }}</td>
          <!-- <td class="has-text-right"
          @dblclick="addSingleNewItem(item, itemCategoryTypes.RWA, true)">{{ getDisplayValue(itemCategoryTypes.RWA, item.rwaHour) | formatNumber($userInfo.locale) }}</td>
         -->

          <td class="has-text-right"
            @click="addSingleNewItem(item, itemCategoryTypes.RR, true)">
            <div class="labour-times is-flex is-justify-content-end"
              :class="{ 'has-text-primary has-text-weight-bold': findItem(item, itemCategoryTypes.RR) }">
              <div style="width: 20px;">
                <span class="mdi mdi-plus add-icon" />
              </div>
              {{ getDisplayValue(itemCategoryTypes.RR, item.rrHour) | formatNumber($userInfo.locale) }}
            </div>
          </td>

          <td class="has-text-right"
            @click="addSingleNewItem(item, itemCategoryTypes.REP, true)">
            <div class="labour-times is-flex is-justify-content-end"
              :class="{ 'has-text-primary has-text-weight-bold': findItem(item, itemCategoryTypes.REP) }">
              <div style="width: 20px;">
                <span class="mdi mdi-plus add-icon" />
              </div>
              {{ getDisplayValue(itemCategoryTypes.REP, item.repairHour) | formatNumber($userInfo.locale) }}
            </div>
          </td>

          <td class="has-text-right"
            @click="addSingleNewItem(item, itemCategoryTypes.PAINT, true)">
            <div class="labour-times is-flex is-justify-content-end"
              :class="{ 'has-text-primary has-text-weight-bold': findItem(item, itemCategoryTypes.PAINT) }">
              <div style="width: 20px;">
                <span class="mdi mdi-plus add-icon" />
              </div>
              {{ getDisplayValue(itemCategoryTypes.PAINT, item.paintHour) | formatNumber($userInfo.locale) }}
            </div>
          </td>

          <td class="has-vertical-middle has-text-centered is-content-width">
            <a class="button is-primary is-small is-inverted"
              @click="addNewItems(item, index)">
              <span class="icon is-medium">
                <i class="mdi mdi-plus mdi-24px" />
              </span>
            </a>
          </td>

        </tr>
        <template slot="empty">
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <span icon="icon is-large">
                <i class="mdi mdi-48px mdi-emoticon-sad" />
              </span>
              <p>Nothing</p>
            </div>
          </section>
        </template>
      </bulma-table>
    </div>
    <div>
      <butterfly-modal v-if="isButterflyModalActive"
        :active.sync="isButterflyModalActive"
        v-model="selectedButterflyCodes"
        @filterButterfly="butterflySearch"
        @reset="reset"
        @done="filterButterly"
        @cancel="cancel">
        <div slot="text-content">
          <butterfly v-if="isButterflyModalActive"
            v-model="selectedButterflyCodes" />
        </div>
      </butterfly-modal>
    </div>
    <emta-registration-modal v-if="isEmtaRegistrationModalActive"
      :active.sync="isEmtaRegistrationModalActive"
      icon-name="mdi-account-plus"
      icon-type="primary"
      @register="registerEmta"
      @cancel="cancelEmta" />
    <emta-vehicle-selection-modal v-if="isEmtaVehicleSelectionModalActive"
      :active.sync="isEmtaVehicleSelectionModalActive"
      v-model="emtaVehicle"
      icon-name="mdi-car"
      icon-type="primary"
      :make="make"
      :model="model"
      :year="year"
      @select="selectEmtaVehicle"
      @cancel="cancelEmtaVehicle" />
  </div>
</template>

<script>
import QuoteService from '../QuoteService'
import { AvailableLabourColumns } from '../columns'
import { ItemCategoryTypes, QuotingMethodTypes, LabourTimeTypes } from '@/enums'
import BulmaTable from '@/components/BulmaTable'
import _debounce from 'lodash.debounce'
import { roundAwayFromZero } from '@/components/utils/AccountingFunctions'
import Guid from '@/components/Guid'
import { NumberFiltersMixin } from '@/components/mixins/filters'
import _isEmpty from 'lodash/isEmpty'
import { Butterfly, ButterflyModal } from '@/components/Butterfly'
import { EmtaRegistrationModal, EmtaVehicleSelectionModal } from '@/components/emta'
import EmtaService from '@/components/emta/EmtaService'
// import HttpStatus from '@/components/http-status'
import StoreMixin from '../storeMixin'
import _cloneDeep from 'lodash/cloneDeep'
import { QuoteItemModel } from '@/classes/viewmodels'

export default {
  name: 'AvailableLabours',
  components: {
    BulmaTable,
    Butterfly,
    ButterflyModal,
    // EmtaRegistration,
    EmtaRegistrationModal,
    // EmtaVehicleSelection,
    EmtaVehicleSelectionModal
  },
  filters: {},
  mixins: [NumberFiltersMixin, StoreMixin],
  props: {
    value: {
      type: Array,
      default: function () {
        return []
      }
    },
    quoteId: {
      type: String,
      default: ''
    },
    nextLineNumber: {
      type: Number,
      default: 0
    },
    pageSize: {
      type: Number,
      default: 8
    },
    rrRate: {
      type: Number,
      default: 0
    },
    rwaRate: {
      type: Number,
      default: 0
    },
    repairRate: {
      type: Number,
      default: 0
    },
    paintRate: {
      type: Number,
      default: 0
    },
    shopRate: {
      type: Number,
      default: 0
    },
    vehiclePaintGroup: {
      type: String,
      default: ''
    },
    quotingMethod: {
      type: String,
      default: ''
    },
    scheduleId: {
      type: String,
      default: ''
    },
    bodyId: {
      type: String,
      default: ''
    },
    make: {
      type: String,
      default: ''
    },
    model: {
      type: String,
      default: ''
    },
    year: {
      type: Number,
      default: 0
    },
    labourType: {
      type: String,
      default: LabourTimeTypes.NRMA.replace('-', '').toLowerCase()
    },
    isNtar: {
      type: Boolean,
      default: false
    },
    showScrollOnAdd: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      innerValue: null,
      selectedRow: null,
      isTableLoading: false,
      totalRows: 0,
      filter: {
        countryId: Guid.empty(),
        companyId: Guid.empty(),
        query: '',
        butterflyCode: '',
        scheduleId: this.scheduleId || '',
        bodyId: this.bodyId || '',
        bodySize: '',
        paintGroup: this.vehiclePaintGroup || '',
        variantId: 0,
        nvic: '',
        sortColumn: '',
        sortOrder: '',
        pageIndex: 1,
        pageSize: this.pageSize
      },
      emtaVehicle: {
        makeId: '',
        modelId: '',
        year: null,
        variantId: '',
        nvic: ''
      },
      availableItems: null,
      selectedButterflyCodes: [],
      addedItems: [],
      selectedTimeType: this.labourType.toLowerCase(), // LabourTimeTypes.NRMA.replace('-', '').toLowerCase(),
      isButterflyModalActive: false,
      selectedRacqBodySize: 'A',
      selectedWesfBodySize: 'Medium',
      isEmtaRegistrationModalActive: false,
      isEmtaVehicleSelectionModalActive: false
    }
  },
  computed: {
    columns() {
      return AvailableLabourColumns
    },
    labourTimeTypes() {
      return LabourTimeTypes
    },
    newItems() {
      return this.value.filter((i) => i.isNew && !i.deleted)
    },
    itemCategoryTypes() {
      return ItemCategoryTypes
    },
    maxAddedItemsLineNumber() {
      return Math.max(...this.addedItems.map((a) => a.lineNumber), 0)
    },
    validEmtaVehicle() {
      return this.emtaVehicle.variantId !== '' && this.emtaVehicle.makeId !== '' && this.emtaVehicle.modelId !== '' && this.emtaVehicle.year !== 0
    }
  },
  async created() {
    // this.innerValue = this.value
    this.filter.companyId = this.$userInfo.companyId
    this.emtaVehicle = _cloneDeep(this.emtaVehicleStore)
    this.getAvailableLabours()
  },
  mounted() {},
  methods: {
    async getAvailableLabours() {
      let registered = true
      if (this.selectedTimeType === this.labourTimeTypes.RACQ.replace('-', '').toLowerCase()) {
        this.filter.bodySize = this.selectedRacqBodySize
      } else if (this.selectedTimeType === this.labourTimeTypes.WESF.replace('-', '').toLowerCase()) {
        this.filter.bodySize = this.selectedWesfBodySize
      } else if (this.selectedTimeType === this.labourTimeTypes.eMTA.replace('-', '').toLowerCase()) {
        registered = await this.isEmtaRegistered()
        if (!this.validEmtaVehicle && registered) {
          this.isEmtaVehicleSelectionModalActive = true
          return
        } else if (this.validEmtaVehicle && registered) {
          this.isTableLoading = true
          this.filter.nvic = this.emtaVehicle.nvic
          this.filter.variantId = this.emtaVehicle.variantId
          if (!this.filter.scheduleId) {
            this.filter.scheduleId = `U${this.emtaVehicle.variantId.slice(-3)}`
          }
          const response = await EmtaService.updateEmtaCatalogue(this.filter)
          console.log(response)
          // if (response.status === HttpStatus.NO_CONTENT) {
          //   this.$notification.openNotificationWithType('warning', 'e-MTA', response.statusText)
          // }
          this.isTableLoading = false
        }
      }

      if (registered) {
        this.isTableLoading = true
        this.availableItems = await QuoteService.getAvailableLabourItems(this.filter, this.selectedTimeType)
        this.totalRows = this.availableItems.length > 0 ? this.availableItems[0].totalRows : 0
        this.isTableLoading = false
      } else {
        this.isEmtaRegistrationModalActive = true
      }
    },
    highlightSelected(index, event) {
      this.selectedRow = index
    },
    onPageChange(pageIndex) {
      this.filter.pageIndex = pageIndex
      this.getAvailableLabours()
    },
    sort(name, order) {},
    reset() {
      this.selectedButterflyCodes = []
      this.filter.query = ''
      this.filter.butterflyCode = ''
      this.filter.pageIndex = 1
      this.getAvailableLabours()
    },
    search: _debounce(function () {
      this.filter.pageIndex = 1
      this.getAvailableLabours()
    }, 500),
    cancel(value) {
      this.selectedButterflyCodes = value
      this.isButterflyModalActive = false
    },
    findItem(item, type) {
      const existingItem = this.value.find(
        (i) => i.itemDesc === item.description && i.itemNo === item.itemNo && !i.deleted && i.itemType === type && !i.deleteOtherItem
      )
      return existingItem
    },
    generateItem(itemNo, description, hourValue, rate, type) {
      let newItem = new QuoteItemModel(this.quoteId, itemNo, description, type)
      newItem.lineNumber = Math.max(this.maxAddedItemsLineNumber + 1, this.nextLineNumber)
      newItem.hourValue = this.quotingMethod === QuotingMethodTypes.Hour ? hourValue : roundAwayFromZero(hourValue * rate)
      newItem.rate = rate
      newItem.dollarValue = roundAwayFromZero(hourValue * rate)
      newItem.opgCode = this.vehiclePaintGroup
      newItem.sortNo = this.value.length ? Math.max(...this.value.map((i) => i.sortNo)) + 1 : 1
      return newItem
    },
    addSingleNewItem(item, type, emit = false) {
      let hour = 0
      let rate = 0
      switch (type) {
        case ItemCategoryTypes.RR:
          hour = item.rrHour
          rate = this.isNtar ? this.shopRate : this.rrRate
          break
        case ItemCategoryTypes.RWA:
          hour = item.rwaHour
          rate = this.isNtar ? this.shopRate : this.rwaRate
          break
        case ItemCategoryTypes.REP:
          hour = item.repairHour
          rate = this.isNtar ? this.shopRate : this.repairRate
          break
        case ItemCategoryTypes.PAINT:
          hour = item.paintHour
          rate = this.isNtar ? this.shopRate : this.paintRate
          break
      }
      if (!this.findItem(item, type)) {
        const newItem = this.generateItem(item.itemNo, item.description, hour, rate, type)
        this.addedItems.splice(this.addedItems.length, 1, newItem)
        if (emit) {
          // this.innerValue.splice(this.innerValue.length, 1, newItem)
          this.$emit('item-added', this.addedItems)
          this.addedItems = []
        }
      } else {
        this.$notification.openNotificationWithType('warning', 'Labour', `${type} item is already in selected list. Item not added.`)
      }
    },
    addNewItems(item, index) {
      if (item.rrHour !== 0) {
        this.addSingleNewItem(item, ItemCategoryTypes.RR)
      }
      if (item.repairHour !== 0) {
        this.addSingleNewItem(item, ItemCategoryTypes.REP)
      }
      if (item.paintHour !== 0) {
        this.addSingleNewItem(item, ItemCategoryTypes.PAINT)
      }

      if (!_isEmpty(this.addedItems)) {
        // const vm = this
        // this.addedItems.forEach(function(newItem) {
        //   vm.innerValue.splice(vm.innerValue.length, 1, newItem)
        // })
        this.$emit('item-added', this.addedItems)
        this.addedItems = []
      }
    },
    getDisplayValue(type, value) {
      let rate = 0
      switch (type) {
        case ItemCategoryTypes.RR:
          rate = this.isNtar ? this.shopRate : this.rrRate
          break
        // case ItemCategoryTypes.RWA:
        //   rate = this.isNtar ? this.shopRate : this.rwaRate
        //   break
        case ItemCategoryTypes.REP:
          rate = this.isNtar ? this.shopRate : this.repairRate
          break
        case ItemCategoryTypes.PAINT:
          rate = this.isNtar ? this.shopRate : this.paintRate
          break
      }
      return this.quotingMethod === QuotingMethodTypes.Dollar ? roundAwayFromZero(rate * value) : value
    },
    butterflySearch() {
      this.isButterflyModalActive = true
    },
    filterButterly() {
      this.isButterflyModalActive = false
      this.filter.butterflyCode = this.selectedButterflyCodes.join()
      this.getAvailableLabours()
    },
    emtaTest() {
      this.isEmtaVehicleSelectionModalActive = !this.isEmtaVehicleSelectionModalActive
    },
    registerEmta() {
      this.isEmtaRegistrationModalActive = false
      this.isEmtaVehicleSelectionModalActive = true
    },
    cancelEmta() {
      this.isEmtaRegistrationModalActive = false
    },
    async isEmtaRegistered() {
      const result = await EmtaService.isEmtaRegistered()
      return result
    },
    selectEmtaVehicle() {
      this.setEmtaVehicle(_cloneDeep(this.emtaVehicle))
      this.getAvailableLabours()
      this.isEmtaVehicleSelectionModalActive = false
    },
    cancelEmtaVehicle() {
      this.isEmtaVehicleSelectionModalActive = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/themes/default/variables';

.labour-times {
  cursor: pointer;
  .add-icon {
    // hide the icon
    display: none;
  }

  &:hover {
    color: $primary;
    .add-icon {
      margin-right: 4px;
      display: inline-block;
    }
  }
}
</style>
